import React, { useState } from 'react';

import StarRatingComponent from 'react-star-rating-component';
import { useAuth0 } from '@auth0/auth0-react';

const WorkoutResultForm = (props) => {
	const { user } = useAuth0();
    const [rating, setRating] = useState(1);
	const [score, setScore] = useState('');

	const scoreChanged = (event) => {
	  	setScore(event.target.value);
	}

	const ratingChanged = (newRating) => {
		setRating(newRating);
	}
  
	const handleSubmit = (event) => {
	  	event.preventDefault();

		const result = {
			workoutId: props.workoutId,
			athlete: user?.name ?? user?.email,
			score: score,
			userInfo: {
				"Name": user?.name,
				"Email": user?.email,
			},
			rating: rating
		}

	  	props.handleSave(result)
	}
  
	return (
		<section className="modal-section">
			<span>
				<p>Rating:</p>
				<StarRatingComponent 
					name={props.workoutId + "-rating"}
					starCount={5} 
					value={rating} 
					onStarClick={ratingChanged} 
					starColor={'#FFC0CB'} 
					className={"rating"} 
				/>
			</span>

			<form onSubmit={handleSubmit}>
				<label>
					<p>Score:</p>
					<textarea name="workout-result-desc" aria-label="workout-result-desc" type="text" onChange={scoreChanged} />
				</label>
				<br/> 
				<input className="button submit" type="submit" value="Submit" />
			</form>
		</section>		
	);
}

export default WorkoutResultForm;