import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const WorkoutForm = (props) => {
	const { user } = useAuth0();
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');

	const nameChanged = (event) => {
	  	setName(event.target.value);
	}

	const descriptionChanged = (event) => {
		setDescription(event.target.value);
	}
  
	const handleSubmit = (event) => {
	  	event.preventDefault();

		const result = {
			date: new Date().toISOString(),
			provider: user?.email,
			name: name,
			description: description
		}

	  	props.handleSave(result)
	}
  
	return (
		<section className="modal-section">
			<form onSubmit={handleSubmit}>
				<label>
					<p>Name:</p>
					<input name="workout-name" aria-label="workout-name" type="text" onChange={nameChanged} />
					<p>Description:</p>
					<textarea name="workout-desc" aria-label="workout-desc" type="text" onChange={descriptionChanged} />
				</label>
				<br/> 
				<input className="button submit" type="submit" value="Add" />
			</form>
		</section>		
	);
}

export default WorkoutForm;