import React from 'react';

export default class SearchComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchString: "",
		};
	}

	searchInputUpdate = (event) => {
		const searchString = event.target.value;
		this.setState({ searchString }, () => {
			this.props.searchHandler(this.state.searchString);
		});
	};

	submitSearch = () => {
		this.props.searchHandler(this.state.searchString);
	}

	random = () => {
		this.props.randomHandler();
	}

	clear = () => {
		this.setState({searchString: ""});
		this.props.clearHandler();
	}

	render() {
		return ( 
			<span className="search-container">
				<input name="search-desc" aria-label="search-desc" type="text" value={this.state.searchString} onChange={this.searchInputUpdate} />
				<i className="fa fa-search header-icon" onClick={this.submitSearch}></i>
				<i className="fa fa-random header-icon" onClick={this.random}></i>
				<i className="fa fa-minus-circle header-icon" onClick={this.clear}></i>
			</span>	
		)
	}
}