import React, { useState, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ReactPlayer from 'react-player'

const LiftResultForm = (props) => {
	const { user } = useAuth0();
	const { 'https://hasiwod.carldanell.se/user_role': role } = user;
    const [reps, setReps] = useState(1);
	const [weight, setWeight] = useState(1);
	const [comment, setComment] = useState('');
	const [liftId, setLift] = useState("2");
	const [video, setVideo] = useState(null);

	const commentChanged = (event) => {
		setComment(event.target.value);
	}

	const liftChanged = (event) => {
		const newId = props.lifts.find(x => x.name === event.target.value).id;
		setLift(newId);
	}

	const repsChanged = (event) => {
		let val = parseInt(event.target.value);
		setReps(val);
	}

	const weightChanged = (event) => {
		let val = parseFloat(event.target.value);
		setWeight(val);
	}

	const onFileChange = (event) => {
        setVideo(event.target.files[0]);
    };

	const clearVideo = () => {
        setVideo(null);
    };
 
	const handleSubmit = (event) => {	
		event.preventDefault();

	  	const result = {
			liftId: liftId,
		 	athlete: user?.name ?? user?.email,
		  	userInfo: {
			  	"Name": user?.name,
				"Email": user?.email,
		  	},
			comment: comment,
		  	weight: weight,
			reps: reps,
			video: video
	  	}
		
		props.handleSave(result);
  	}

	  const videoComponent = useMemo(() => {
		return (
			role === 'admin' && (
				<div>
					{
						video === null ? (
							<div className="file-input">
								<input title="VideoInput" id="videoinput" type="file" onChange={onFileChange} />
								<label htmlFor="videoinput">Select Video</label>
							</div>
						) : (
							<>
								<p>Name: {video?.name}</p>
								<div className='player-wrapper'>
									<ReactPlayer 
										className='react-player'
										url={URL.createObjectURL(video)} 
										width='100%' 
										height='100%' 
										controls={true} 
									/>
								</div>
								<br/>
								<button className="button submit" onClick={clearVideo}>Clear</button>
							</>
						)
					}
				</div>
			)
		);
	  }, [video, role]);

	return (
		<section className="modal-section">
			<form onSubmit={handleSubmit}>
				<div>
					<select title="LiftType" name="lift-type-select" onChange={liftChanged}>
						{props.lifts.map((option) => (<option key={option.id}>{option.name}</option>))}
					</select>
				</div>
				<div className="liftresult-form-container">
					<div>
						<h4>Weight</h4>
						<input name="weight-name" aria-label="weight-name" min="1" formNoValidate type="number" value={weight} onChange={weightChanged} />
					</div>
					<div>
						<h4>Reps</h4>
						<input name="reps-name" aria-label="reps-name" min="1" type="number" value={reps} onChange={repsChanged} />
					</div>
					</div>
					<div>
						<h4>Comment</h4>
						<textarea name="comment" aria-label="lift-result-desc" type="text" onChange={commentChanged} />
						<p></p>
					</div>
					<div>
					{
						videoComponent
					}
					</div>
				<br/> 
				<input className="button submit" type="submit" value="Submit" />
			</form>
		</section>		
	);
}

export default LiftResultForm;