import React from 'react';
import { Link } from 'react-router-dom';

import LogoText from '../../logo_text.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire } from '@fortawesome/free-solid-svg-icons'
import { faDumbbell } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'


const NavBar = () => {
	return (
		<>
            <div className="nav-bar">
                <div>
                    <img className="nav-bar-img" src={LogoText} alt="hasi"/>
                </div>
                
                <div className="nav-bar-icons">
                    <Link to="/workouts" role="button" className="nav-button wrapper" aria-label="workouts">
                        <FontAwesomeIcon icon={faFire} size="2x" />
                    </Link>
                    <Link to="/lifts" role="button" className="nav-button wrapper" aria-label="workouts">
                        <FontAwesomeIcon icon={faDumbbell} size="2x"/>
                    </Link>
                    <Link to="/activity" role="button" className="nav-button wrapper" aria-label="workouts">
                        <FontAwesomeIcon icon={faBook} size="2x"/>
                    </Link>
                    <Link to="/profile" role="button" className="nav-button wrapper" aria-label="workouts">
                        <FontAwesomeIcon icon={faUser} size="2x"/>
                    </Link>
                </div>
            </div>
            <hr/>
        </>
	);
}

export default NavBar;